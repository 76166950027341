import { BlitzPage } from "@blitzjs/next"
import Layout from "app/core/layouts/Layout"

const Home: BlitzPage = () => {
  return null
}

Home.authenticate = true
Home.suppressFirstRenderFlicker = true
Home.redirectAuthenticatedTo = "/pipelines"
Home.getLayout = (page) => <Layout title="Home">{page}</Layout>

export default Home
